<template>
  <div>
    <div v-if="data">
      <b-row
        v-for="(item, index) in defaultFields"
        :key="`field-${index}`"
        align-v="center"
        :class="{'mb-50 pb-50 border-bottom': index < defaultFields.length - 1}"
      >
        <!-- Label -->
        <b-col
          cols="4"
          class="text-right font-weight-bold"
        >
          {{ item.label || _.startCase(item.key) }}
        </b-col>

        <!-- Value -->
        <b-col class="overflow-hidden">
          <TableField
            :id="_.get(data, item.id)"
            :data="data"
            :value="_.get(data, item.key)"
            :prefix="item.prefix"
            :suffix="item.suffix"
            :type="item.type"
            :map="item.map"
            :image="_.get(data, item.image)"
            :url="item.url"
            :has-detail="hasDetail"
            :text="_.get(data, item.text)"
            show-copy
          />
        </b-col>
      </b-row>
    </div>

    <!-- Loading state -->
    <div
      v-else
      class="py-5 text-center"
    >
      <b-spinner variant="primary" />
      <div class="mt-2">
        Please wait..
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'
import TableField from '@/layouts/components/TableField.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    TableField,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    fields: {
      type: Array,
      required: true,
    },
    lazy: {
      type: Boolean,
      required: false,
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // hide field with visibility false
    // most likely because those fields
    // are used as filter field or dummy
    defaultFields() {
      return this.fields.filter(item => item.visibility !== false)
    },
  },
  mounted() {
    // do not auto load on lazy mode
    if (!this.lazy) {
      this.loadData(this.$route.params.id)
    }
  },
  methods: {
    loadData() {
      const id = this.$route.hash.replace(/#/g, '') || this.$route.params.id
      this.$emit('load', id)
    },
  },
}
</script>

<style lang="scss">
</style>
