<template>
  <div class="d-flex align-items-center whitespace-pre">
    <!-- Prefix -->
    <span v-if="hasValue && defaultPrefix">{{ defaultPrefix }}</span>

    <!-- Id column -->
    <router-link
      v-if="hasValue && defaultType === 'id'"
      v-b-tooltip.hover.ds250.v-dark.top="value"
      :to="detailRoute"
      class="font-monospace"
    >#{{ typeof value === 'string' ? value.substr(0, 6) : value }}</router-link>

    <!-- Image type's field -->
    <div
      v-else-if="hasValue && defaultType === 'image'"
    >
      <a
        :href="value"
        target="_blank"
      >
        <b-avatar
          variant="light"
          rounded="sm"
          :src="defaultImage"
          size="40px"
        />
      </a>
    </div>

    <!-- Badges type's field -->
    <div
      v-else-if="hasValue && value.length && defaultType === 'badges'"
      class="d-flex gap-1"
    >
      <b-badge
        v-for="(item, index) in defaultBadges"
        :key="`badges-field-${index}`"
        :variant="_.get(map, item, 'secondary')"
        class="text-capitalize"
      >
        {{ item }}
      </b-badge>
      <b-badge
        v-if="value.length > 2"
        v-b-tooltip.v-dark.top="{ title: hiddenBadges.join(', '), customClass: 'text-capitalize' }"
        class="text-capitalize"
      >
        + {{ value.length - 1 }} more
      </b-badge>
    </div>

    <!-- Badge type's field -->
    <b-badge
      v-else-if="hasValue && defaultType === 'badge'"
      :variant="_.get(map, value, 'secondary')"
      class="text-capitalize"
    >
      <span v-if="text">{{ text }}</span>
      <span v-else>{{ value.toString().replace(/_/g, ' ') }}</span>
    </b-badge>

    <!-- Dot type's field -->
    <div
      v-else-if="hasValue && defaultType === 'dot'"
      class="d-flex align-items-center text-nowrap"
    >
      <div :class="`text-${_.get(map, value, 'secondary')}`">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="8"
            cy="6.5"
            r="5.5"
          />
        </svg>
      </div>

      <span class="text-capitalize ml-25">{{ value.toString().replace(/_/g, ' ') }}</span>
    </div>

    <!-- Profile type's field -->
    <!-- Suitable for general profile -->
    <!-- e.g. User profile, Product profile -->
    <div
      v-else-if="(hasValue || image) && defaultType === 'profile'"
      class="d-flex align-items-center line-clamp-1 whitespace-normal w-max-content"
    >
      <!-- Avatar -->
      <a
        :href="image"
        target="_blank"
      >
        <b-avatar
          size="32px"
          variant="light"
          class="text-body"
          :src="defaultProfileImage"
        />
      </a>

      <!-- Name -->
      <a
        v-if="hasValue && defaultUrl"
        :href="defaultUrl"
        target="_blank"
        class="ml-75 line-clamp-1"
        :title="value"
      >{{ value }}</a>
      <span
        v-else-if="hasValue"
        class="ml-75 line-clamp-1"
        :title="value"
      >{{ value }}</span>
      <i
        v-else
        class="ml-75 text-muted"
      >No name</i>
    </div>

    <!-- Datetime type's field -->
    <span
      v-else-if="hasValue && defaultType === 'datetime'"
      class="text-capitalize"
    >{{ value | datetime }}</span>

    <!-- Phone type's field -->
    <span v-else-if="hasValue && defaultType === 'phone'">{{ value | phone }}</span>

    <!-- Datetime type's field -->
    <span
      v-else-if="hasValue && defaultType === 'date'"
      class="text-capitalize"
    >{{ value | date }}</span>

    <!-- Email format -->
    <a
      v-else-if="hasValue && defaultType === 'email'"
      :href="`mailto:${value}`"
    >{{ value }}</a>

    <!-- Rating format -->
    <div
      v-else-if="hasValue && defaultType === 'rating'"
      class="d-flex align-items-center gap-1"
    >
      {{ value }}
      <feather-icon
        icon="StarIcon"
        class="text-warning"
        size="16"
      />
    </div>

    <!-- Country field -->
    <CountryField
      v-else-if="hasValue && defaultType === 'country'"
      :code="value"
    />

    <!-- Gold format -->
    <div
      v-else-if="hasValue && defaultType === 'gold'"
      class="d-flex align-items-center"
    >
      <b-img
        :src="goldIcon"
        width="14px"
        class="mr-25"
      />
      <span>{{ value | number }}</span>
    </div>

    <!-- Meta array -->
    <div
      v-else-if="hasValue && !_.isEmpty(value) && defaultType === 'meta' && Array.isArray(value)"
      class="font-monospace w-100 overflow-hidden"
    >
      <div
        v-for="(item, index) in value"
        :key="`meta-field-${index}`"
        class="border p-25"
      >
        <div
          v-for="(attr, attrIndex) in Object.keys(item).map((key) => ({key, value: item[key]}))"
          :key="`meta-field-${index}-${attrIndex}`"
          class="px-25 text-ellipsis"
        >{{ attr.key }}: {{ attr.value }}</div>
      </div>
    </div>

    <!-- Meta object -->
    <div
      v-else-if="hasValue && !_.isEmpty(value) && defaultType === 'meta'"
      class="font-monospace w-100 overflow-hidden border p-25"
    >
      <div
        v-for="(item, index) in Object.keys(value).map((key) => ({key, value: value[key]}))"
        :key="`meta-field-${index}`"
        class="px-25 text-ellipsis"
      >{{ item.key }}: {{ item.value }}</div>
    </div>

    <!-- Url -->
    <a
      v-else-if="hasValue && defaultType === 'url'"
      :href="defaultUrl"
      target="_blank"
    >{{ value }}</a>

    <!-- Number format -->
    <span
      v-else-if="hasValue && typeof value === 'number'"
    >{{ value | number }}</span>

    <!-- Boolean -->
    <div v-else-if="hasValue && typeof value === 'boolean'">
      <feather-icon
        v-if="value"
        icon="CheckCircleIcon"
        class="text-success"
        size="20"
      />
      <feather-icon
        v-else
        icon="XCircleIcon"
        class="text-danger"
        size="20"
      />
    </div>

    <!-- Default -->
    <div
      v-else-if="hasValue && typeof value !== 'object'"
      class="line-clamp-1 d-block"
    >{{ value }}</div>

    <!-- Empty -->
    <div
      v-else
      class="text-muted s-12 py-25"
    >●</div>

    <!-- Suffix -->
    <span v-if="hasValue && defaultSuffix">{{ defaultSuffix }}</span>

    <!-- Copy text -->
    <!-- should have value, array should have item(s) -->
    <!-- or not an array but have value-->
    <div
      v-if="showCopy && hasValue && (value.length || typeof value !== 'object')"
      class="position-relative"
    >
      <b-button
        ref="btn-copy"
        variant="flat-dark"
        class="btn-icon btn-copy ml-50"
        size="sm"
        @click.prevent="copyText(value, $event)"
      >
        <feather-icon
          icon="CopyIcon"
          class="pointer-events-none"
          size="16"
        />
      </b-button>
      <!-- Tooltip -->
      <b-tooltip
        ref="tooltip-copy"
        :target="() => $refs['btn-copy']"
        title="Disalin!"
        placement="top"
        no-fade
        disabled
      />
    </div>
  </div>
</template>

<script>
import { copy } from '@/helper/clipboard'
import CountryField from '@/layouts/components/CountryField.vue'
import {
  BBadge,
  BButton,
  BAvatar,
  BImg,
  BTooltip,
} from 'bootstrap-vue'
import goldIcon from '@/assets/images/icons/gold.svg'

export default {
  components: {
    BBadge,
    BButton,
    CountryField,
    BAvatar,
    BImg,
    BTooltip,
  },
  props: {
    value: {
      type: [String, Number, Array, Boolean],
      default: '',
    },
    prefix: {
      type: [String, Object],
      default: '',
    },
    suffix: {
      type: [String, Object],
      default: '',
    },
    type: {
      type: [String, Object],
      default: '',
    },
    map: {
      type: Object,
      default: () => {},
    },
    image: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    url: {
      type: [String, Object],
      default: '',
    },
    hasDetail: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      goldIcon,
    }
  },
  computed: {
    defaultImage() {
      return this.$options.filters.thumbnail(this.value, 40)
    },
    defaultProfileImage() {
      return this.$options.filters.thumbnail(this.image, 40)
    },
    // get only first item if there are more than 2 items
    defaultBadges() {
      if (!this.value) {
        return undefined
      }

      if (this.value.length > 2) {
        return this.value.slice(0, 1)
      }

      return this.value
    },
    // get dynamic prefix
    defaultPrefix() {
      if (!this.prefix) {
        return undefined
      }

      if (typeof this.prefix === 'string') {
        return this.prefix
      }

      if (typeof this.prefix !== 'object') {
        return undefined
      }

      // dynamic value
      return this.prefix.callback(this.data)
    },
    // get dynamic suffix
    defaultSuffix() {
      if (!this.suffix) {
        return undefined
      }

      if (typeof this.suffix === 'string') {
        return this.suffix
      }

      if (typeof this.suffix !== 'object') {
        return undefined
      }

      // dynamic value
      return this.suffix.callback(this.data)
    },
    // get dynamic type
    defaultType() {
      if (!this.type) {
        return undefined
      }

      if (typeof this.type === 'string') {
        return this.type
      }

      if (typeof this.type !== 'object') {
        return undefined
      }

      // dynamic value
      return this.type.callback(this.data)
    },
    defaultUrl() {
      if (!this.url && (this.value.includes('http') || this.value.includes('www') || this.value.includes('.com'))) {
        return this.value
      }

      if (!this.url) {
        return undefined
      }

      if (typeof this.url === 'string') {
        return this.url
      }

      if (typeof this.url !== 'object') {
        return undefined
      }

      // dynamic value
      return this.url.callback(this.data)
    },
    // get only first item if there are more than 2 items
    hiddenBadges() {
      if (!this.value) {
        return undefined
      }

      if (this.value.length > 2) {
        return this._.differenceWith(this.value, this.defaultBadges, this._.isEqual)
      }

      // the default is no hidden badges
      return []
    },
    detailRoute() {
      // if already in detail route
      if (this.$route.path.includes(this.value)) {
        return this.$route.path
      }

      if (this.hasDetail) {
        // return detail route
        return `${this.$route.path}/${this.value}`
      }

      // open popup instead
      return `${this.$route.path}#${this.value}`
    },
    // using if(value) and !_.isEmpty for number 0 will return false
    hasValue() {
      return this.value !== undefined && this.value !== null && this.value !== ''
    },
  },
  methods: {
    copyText(val) {
      copy(val.toString())
        .then(() => {
          // show tooltip only when success
          this.$refs['tooltip-copy'].$emit('open')

          // hide tooltip after half a second
          setTimeout(() => {
            this.$refs['tooltip-copy'].$emit('close')
          }, 500)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-table tbody {
  td {
    .btn-copy {
      z-index: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(0);
      opacity: 0;
      transition: opacity 0.1s ease-in-out, transform 0s linear;
      transition-delay: 0s;
    }

    &:hover {
      .btn-copy {
        transform: translateY(-50%) scale(1);
        opacity: 1; // do not use 100%, in production will changed to 1%
        transition-delay: 0.5s;
      }
    }
  }
}
</style>
